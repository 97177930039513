// import('./bootstrap');
// import ReactDOM from 'react-dom/client';
// if (process.env.NODE_ENV === 'development') {
//     require('dev-tools/dist/index.css');
//     const Devtools = require('dev-tools').default;
//     const root2 = ReactDOM.createRoot(document.getElementById('dev-tools'));
//     root2.render(
//         <Devtools />
//     );
// }
import './public-path';
import('./bootstrap');
